import { Component } from "@angular/core";
import { ThemeVariables, ThemeRef, lyl, StyleRenderer } from "@alyle/ui";
import { ConnectionService } from "ng-connection-service";
import * as $ from "jquery";
import { Router, ActivatedRoute, NavigationEnd, RouterOutlet } from "@angular/router";
import { filter } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Title } from "@angular/platform-browser";

import { HttpCancelService } from "./helpers/httpcancel.service";
import { Services } from "../app/helpers/Services";

declare const gtag: Function;

const STYLES = (theme: ThemeVariables, ref: ThemeRef) => {
  const __ = ref.selectorsOf(STYLES);
  return {
    $global: lyl`{
      body {
        background-color: ${theme.background.default}
        color: ${theme.text.default}
        font-family: ${theme.typography.fontFamily}
        margin: 0
        direction: ${theme.direction}
      }
    }`,
    root: lyl`{
      display: block
    }`,
  };
};

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"],
    providers: [StyleRenderer],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
  isConnected = true;
  noInternetConnection: boolean = false;
  url: any;
  appservices: Services;
  // readonly classes = this.sRenderer.renderSheet(STYLES, true);
  readonly sRenderer: StyleRenderer;
  readonly classes: string;

  title = "widget";
  domain;
  constructor(
    sRenderer: StyleRenderer,
    private router: Router,
    private connectionService: ConnectionService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private httpCancelService: HttpCancelService
  ) {
    this.sRenderer = sRenderer;
    this.classes = this.sRenderer.renderSheet(STYLES, true) as unknown as string; 
    this.appservices = new Services();
    this.domain = window.location.host.toLowerCase();
    // if (window.location.host.toLowerCase() == environment.DOMAIN_URL)
    //   this.addGTScript();
    // else this.addGAScript();
    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected.hasInternetAccess;

      let pathName = window.location.pathname.split("/").pop();
      // console.log(" pathName :  " +  pathName);
      if (pathName != "no-internet") {
        //  console.log(" overall current_url  " + pathName );
        this.appservices.setSession("current_url", pathName, false);
      }

      // console.log(this.appservices.getSession("current_url", false));
      if (this.isConnected) {
        this.noInternetConnection = false;
      } else {
        // $('no_internet').show();
        // this.noInternetConnection = true;
        // console.log(" router.url : " + router.url);
        // console.log(" this.router.url " + this.router.url);
        // console.log(" window.location.pathname : " + window.location.pathname );

        let pathName = window.location.pathname.split("/").pop();
        // console.log(" pathName :  " +  pathName);
        if (pathName != "no-internet") {
          //  console.log(" Inside "+ window.location.pathname);
          //  console.log(" No internet current_url  " + pathName );
          this.appservices.setSession("current_url", pathName, false);
          setTimeout(() => {
            //   console.log(" Inside time out ");
            this.router.navigateByUrl("no-internet");
            return;
          }, 1000);
          // console.log(" before navigation  ");

          // console.log(this.appservices.getSession("current_url", false));
        }
        // console.log(" went out ");
      }
    });
  }

  ngOnInit(): void {
    // console.log(this.appservices.getSession("current_url", false));
    window.scrollTo(0, 0);
    $(window).scrollTop(0);
    this.url = this.router.url;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.httpCancelService.cancelPendingRequests();

        const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe((data) => {
          let pagetitle = data.title;
          let title = pagetitle.split("::")[0];
          let analytics_title = pagetitle.split("::")[1];
          if (title != null && title != undefined && title != "")
            this.titleService.setTitle(environment.TITLE_PREFIX + title);
          if (
            analytics_title != null &&
            analytics_title != undefined &&
            analytics_title != ""
          )
            this.appservices.sendPageViewAnalyticsData(
              "",
              "",
              event.urlAfterRedirects,
              analytics_title
            );
        });
      });
  }
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
  addGAScript() {
    // let gtagScript: HTMLScriptElement = document.createElement("script");
    // gtagScript.async = true;
    // gtagScript.src =
    //   "https://www.googletagmanager.com/gtag/js?id=" +
    //   environment.GA_TRACKING_ID;
    // document.head.prepend(gtagScript);
    // if (environment.ENV == "Production")
    //   gtag("config", environment.GA_TRACKING_ID, {
    //     send_page_view: false,
    //     debug_mode: true,
    //   });
    // else
    //   gtag("config", environment.GA_TRACKING_ID, {
    //     send_page_view: false,
    //     debug_mode: true,
    //   });
  }
  addGTScript() {
    // let gtagScript: HTMLScriptElement = document.createElement("script");
    // gtagScript.async = true;
    // gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.GA_TRACKING_ID}`;
    // document.head.prepend(gtagScript);
    // gtag("js", new Date());
    // gtag("config", environment.GA_TRACKING_ID, { debug_mode: true });

    // let gtagScript1: HTMLScriptElement = document.createElement("script");
    // gtagScript1.async = true;
    // gtagScript1.src = `https://www.googletagmanager.com/gtag/js?id=${environment.GTM_ID_INDIA}`;
    // document.head.prepend(gtagScript1);
    // gtag("js", new Date());
    // gtag("config", environment.GTM_ID_INDIA, { debug_mode: true });
  }
}
