import { Route } from "@angular/router";

export const appRoutes: Route[] = [
    {
        path: "",
        loadComponent: () => import('./get-started/get-started.component').then(m => m.GetStartedComponent),
        pathMatch: "full",
        data: { title: "Get Started" },
    },
    {
        path: "get-started/:token",
        loadComponent: () => import('./get-started/get-started.component').then(m => m.GetStartedComponent),
        data: { title: "Get Started" },
    },
    {
        path: "get-started",
        loadComponent: () => import('./get-started/get-started.component').then(m => m.GetStartedComponent),
        data: { title: "Get Started" },
    },
    {
        path: "getting-started",
        loadComponent: () => import('./getting-start-page-widget/getting-start-page-widget.component').then(m => m.GettingStartPageWidgetComponent),
        data: { title: "Getting Started Page Widget" },
    },
    {
        path: "consent-agreement",
        loadComponent: () => import('./consent-agreement/consent-agreement.component').then(m => m.ConsentAgreementComponent),
        data: { title: "Consent and Agreement Page Widget" },
    },
    {
        path: "invalid-access",
        loadComponent: () => import('./invalid-access/invalid-access.component').then(m => m.InvalidAccessComponent),
        data: { title: "Invalid Access::Invalid Access" },
    },
    {
        path: "contact-information",
        loadComponent: () => import('./contact-information/contact-information.component').then(m => m.ContactInformationComponent),
        data: { title: "Contact details::Contact details" },
    },
    {
        path: "email-confirmation",
        loadComponent: () => import('./email-confirmation/email-confirmation.component').then(m => m.EmailConfirmationComponent),
        data: { title: "Email Confirmation::Email Confirmation" },
    },
    {
        path: "oral-hygiene",
        loadComponent: () => import('./oral-hygiene/oral-hygiene.component').then(m => m.OralHygieneComponent),
        data: { title: "OHR - Questionnaire::OHR - Questionnaire" },
    },
    {
        path: "oral-health-score-preference",
        loadComponent: () => import('./oral-health-score-preference/oral-health-score-preference.component').then(m => m.OralHealthScorePreferenceComponent),
        data: { title: "Select AI option::Select AI option" },
    },
    {
        path: "upload-teeth-photos",
        loadComponent: () => import('./upload-teeth-photos/upload-teeth-photos.component').then(m => m.UploadTeethPhotosComponent),
        data: { title: "Upload your teeth images::OHR - Upload teeth images" },
    },
    {
        path: "get-user-details",
        loadComponent: () => import('./input-user-contact/input-user-contact.component').then(m => m.InputUserContactComponent),
        data: { title: "Get user details" },
    },
    {
        path: "product-discount",
        loadChildren: () =>
            import("./product-discount/product-discount.module").then(
                (m) => m.ProductDiscountModule
            ),
    },
    {
        path: "oral-health-score",
        loadComponent: () => import('./oral-health-score/oral-health-score.component').then(m => m.OralHealthScoreComponent),
        data: { title: "OHR Rating::OHR - Traffic Light Rating" },
    },
    {
        path: "smile-design-preference",
        loadComponent: () => import('./smile-design-preference/smile-design-preference.component').then(m => m.SmileDesignPreferenceComponent),
        data: { title: "Select your preference::DMS - Customize inputs" },
    },
    {
        path: "upload-smile-photo",
        loadComponent: () => import('./upload-smile-photo/upload-smile-photo.component').then(m => m.UploadSmilePhotoComponent),
        data: { title: "Design My Smile::DMS - Upload image" },
    },
    {
        path: "select-gum-color",
        loadComponent: () => import('./select-gum-color/select-gum-color.component').then(m => m.SelectGumColorComponent),
        data: { title: "Select your preference::DMS - Customize inputs" },
    },
    {
        path: "smile-before-after",
        loadComponent: () => import('./smile-before-after-picture/smile-before-after-picture.component').then(m => m.SmileBeforeAfterPictureComponent),
        data: { title: "Before & After Your Photo::DMS - Before and After" },
    },
    {
        path: "email-success",
        loadComponent: () => import('./email-success/email-success.component').then(m => m.EmailSuccessComponent),
        data: { title: "DMS Email Success::DMS - Report emailed" },
    },
    {
        path: "ohr-email-success",
        loadComponent: () => import('./ohr-email-success/ohr-email-success.component').then(m => m.OhrEmailSuccessComponent),
        data: { title: "OHR Email Success::OHR - Report emailed" },
    },
    {
        path: "process-ohr-images",
        loadComponent: () => import('./process-ohr-images/process-ohr-images.component').then(m => m.ProcessOhrImagesComponent),
        data: { title: "OHR Results::OHR - AI analysis of input images" },
    },
    // { path: 'medical-questions', component: MedicalQuestionsComponent,data : {title: 'Free Consultation::Start page'}},
    // { path: 'select-service', component: MedicalQuestionsComponent,data : {title: 'Free Consultation::Start page'}},
    {
        path: "practice-images",
        loadComponent: () => import('./practice-images/practice-images.component').then(m => m.PracticeImageComponent),
        data: { title: "Patient Image::Patient Images" },
    },
    {
        path: "patient-image-uploaded",
        loadComponent: () => import('./patient-image-uploaded/patient-image-uploaded.component').then(m => m.PatientImageUploadedComponent),
        data: { title: "Patient Image Uploaded::Patient Image Uploaded" },
    },
    {
        path: "payment-successful",
        loadComponent: () => import('./payment-successful/payment-successful.component').then(m => m.PaymentSuccessfulComponent),
        data: { title: "Payment Successful::Payment Successful" },
    },

    {
        path: "payment-access-report",
        loadComponent: () => import('./payment-access-report/payment-access-report.component').then(m => m.PaymentAccessReportComponent),
        data: { title: "Payment Access Report::OHR - RAG paywall" },
    },
    {
        path: "payment-process",
        loadChildren: () =>
            import("./payment-process/payment-process.module").then(
                (m) => m.PaymentProcessModule
            ),
    },

    {
        path: "payment-oral-health-score",
        loadComponent: () => import('./payment-oral-health-score/payment-oral-health-score.component').then(m => m.PaymentOralHealthScoreComponent),
        data: { title: "Payment Oral Health Score::OHR - RAG page" },
    },
    {
        path: "payment-oral-health-score-completed",
        loadComponent: () => import('./payment-oral-health-score-completed/payment-oral-health-score-completed.component').then(m => m.PaymentOralHealthScoreCompletedComponent),
        data: {
            title: "Payment Oral Health Score Completed::OHR - Payment completed",
        },
    },

    {
        path: "make-stripe-payment",
        loadComponent: () => import('./make-stripe-payment/make-stripe-payment.component').then(m => m.MakeStripePaymentComponent),
        data: { title: "Make Stripe Payment::Make stripe payment" },
    },

    {
        path: "user-profile",
        loadComponent: () => import('./user-profile/user-profile.component').then(m => m.UserProfileComponent),
        data: { title: "user-profile::user-profile" },
    },

    {
        path: "login",
        loadComponent: () => import('./login/login.component').then(m => m.LoginComponent),
        data: { title: "login::Start page" },
    },

    {
        path: "purpose-of-visit",
        loadComponent: () => import('./purpose-of-visit/purpose-of-visit.component').then(m => m.PurposeOfVisitComponent),
        data: { title: "Select purpose of visit::Select purpose of visit" },
    },

    {
        path: "select-service",
        loadComponent: () => import('./select-service/select-service.component').then(m => m.SelectServiceComponent),
        data: { title: "Select service::Get Started" },
    },

    {
        path: "no-internet",
        loadComponent: () => import('./no-internet/no-internet.component').then(m => m.NoInternetComponent),
        data: { title: "No Internet::No Internet" },
    },

    {
        path: "signout",
        loadComponent: () => import('./signout/signout.component').then(m => m.SignoutComponent),
        data: { title: "Sign out::Sign out" },
    },
    {
        path: "teeth-whitening-result",
        loadComponent: () => import('./teeth-whitening-result/teeth-whitening-result.component').then(m => m.TeethWhiteningResultComponent),
        data: { title: "Teeth Whitening" },
    },
    {
        path: 'dental-appointment',
        loadChildren: () =>
            import('./dental-appointment/dental-appointment.module').then((m) => m.DentalAppointmentModule),
        data: { title: "Dental Appointment::Dental Appointment" }
    },
    // newly added 
    {
        path: "select-preference",
        loadComponent: () => import('./select-preference/select-preference.component').then(m => m.SelectPreferenceComponent),
        data: { title: "Select Preference::Select Preference" },
    },
    {
        path: "upload-smile-photos",
        loadComponent: () => import('./design-my-smile/upload-smile-photo/upload-smile-photo.component').then(m => m.UploadSmilePhotoComponent),
        data: { title: "Design My Smile::DMS - Upload image" },
    },
    {
        path: "medical-history-form",
        loadComponent: () => import('./medical-history-form/medical-history-form/medical-history-form.component').then(m => m.MedicalHistoryFormComponent),
        data: { title: "Medical History Form::Medical History Form" },
    },
    {
        path: "upload-success",
        loadComponent: () => import('./shared/thank-you/thank-you.component').then(m => m.ThankYouComponent),
        data: { title: "Thank You::Thank You" },
    },
    {
        path: "practice-image",
        loadComponent: () => import('./smile-checkin/practice-image/practice-image.component').then(m => m.PracticeImageComponent),
        data: { title: "Thank You::Thank You" },
    },
    {
        path: "smile-result",
        loadComponent: () => import('./design-my-smile/smile-result/smile-result.component').then(m => m.SmileResultComponent),
        data: { title: "Design My Smile Result::Design My Smile Result" },
    },
    {
        path: "book-appointment",
        loadComponent: () => import('./book-appointment/book-appointment/book-appointment.component').then(m => m.BookAppointmentComponent),
        data: { title: "Book Appointment::Book Appointment" },
    },
    {
        path: 'booking-success',
        loadComponent: () => import('./book-appointment/booking-success/booking-success.component').then(m => m.BookingSuccessComponent),
        data: { title: 'Appointment Success::Appointment Success' }
    },
    {
        path: "**",
        redirectTo: "",
    }
];