import { importProvidersFrom } from "@angular/core";
import "hammerjs";
import {
  LyTheme2,
  StyleRenderer,
  LY_THEME_NAME,
  LY_THEME,
  LY_THEME_GLOBAL_VARIABLES,
  LyHammerGestureConfig,
} from "@alyle/ui";
import {
  Title,
  HAMMER_GESTURE_CONFIG,
  BrowserModule,
  HammerModule,
} from "@angular/platform-browser";
import { MinimaLight, MinimaDark } from "@alyle/ui/themes/minima";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { ErrorInterceptor } from "./core/interceptors/error.interceptor";
import { HttpCancelService } from "./helpers/httpcancel.service";
import { provideLottieOptions } from "ngx-lottie";
import { ConnectionServiceModule } from "ng-connection-service";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideAnimations } from "@angular/platform-browser/animations";
import { LyImageCropperModule } from "@alyle/ui/image-cropper";
import { LySliderModule } from "@alyle/ui/slider";
import { LyButtonModule } from "@alyle/ui/button";
import { LyIconModule } from "@alyle/ui/icon";
import { LyDialogModule } from "@alyle/ui/dialog";
import { MomentModule } from "ngx-moment";
import { NgxTrimDirectiveModule } from "ngx-trim-directive";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { color } from "@alyle/ui/color";
import { ApplicationConfig } from "@angular/core";
import { appRoutes } from "./app.routes";
import {
  PreloadAllModules,
  provideRouter,
  withInMemoryScrolling,
  withPreloading,
} from "@angular/router";
import { DatePipe } from "@angular/common";

export class GlobalVariables {
  testVal = color(0x00bcd4);
  Quepal = {
    default: `linear-gradient(135deg,#11998e 0%,#38ef7d 100%)`,
    contrast: color(0xffffff),
    shadow: color(0x11998e),
  };
  SublimeLight = {
    default: `linear-gradient(135deg,#FC5C7D 0%,#6A82FB 100%)`,
    contrast: color(0xffffff),
    shadow: color(0xb36fbc),
  };
  Amber = {
    default: color(0xffc107),
    contrast: color(0, 0, 0, 0.87),
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      ConnectionServiceModule,
      TimepickerModule.forRoot(),
      BsDatepickerModule.forRoot(),
      SlickCarouselModule,
      LazyLoadImageModule,
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      LyImageCropperModule,
      LySliderModule,
      LyButtonModule,
      LyIconModule,
      LyDialogModule,
      // LyToolbarModule,
      HammerModule,
      MomentModule,
      NgxTrimDirectiveModule,
      // Ng2TelInputModule,
      ModalModule.forRoot(),
      // SharedModule,
      NgxIntlTelInputModule,
      // Gestures,
      // LottieModule.forRoot({ player: playerFactory }),
      NgxSliderModule
    ),
    [LyTheme2],
    [Title],
    [StyleRenderer],
    [DatePipe],
    { provide: LY_THEME_NAME, useValue: "minima-light" },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    { provide: LY_THEME, useClass: MinimaDark, multi: true },
    { provide: LY_THEME_GLOBAL_VARIABLES, useClass: GlobalVariables },
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    HttpCancelService,
    provideHttpClient(withInterceptorsFromDi()),
    provideLottieOptions({
      player: () => import("lottie-web"),
    }),
    provideAnimations(),
    provideRouter(
      appRoutes,
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({ scrollPositionRestoration: "enabled" })
    ),
  ],
};
