import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, takeUntil } from 'rxjs/operators';
import { HttpCancelService } from '../../helpers/httpcancel.service';

@Injectable()

export class ErrorInterceptor implements HttpInterceptor {

  constructor(private httpCancelService: HttpCancelService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        takeUntil(this.httpCancelService.onCancelPendingRequests()),
        //retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage;
          if (error.error instanceof ErrorEvent) {
            // client-side error
            //  errorMessage = Error: ${error.error.message};
          } else {
            // server-side error
            //  errorMessage = Error Code: ${error.status}\nMessage: ${error.message};
          }
          // window.alert(errorMessage);
          // window.location.replace('no-internet');
          return throwError(errorMessage);
        })
      );
  }
}